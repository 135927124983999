// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCfx11VsG6izdWJ3y_r33-JqpjV4jaMvDY",
  authDomain: "fynd-f10cb.firebaseapp.com",
  projectId: "fynd-f10cb",
  storageBucket: "fynd-f10cb.appspot.com",
  messagingSenderId: "70897643492",
  appId: "1:70897643492:web:cc117fdab4174427b221f1"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app); 
export default app;