/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
import { HashLink as Link } from 'react-router-hash-link';
//import { Link } from 'react-router-dom';

import { setDoc, doc } from "firebase/firestore";
import { db } from "../../firebase/firebase-config"

// reactstrap components
import { Form, UncontrolledTooltip, UncontrolledAlert, Button, Container, Row, Col, Card, CardImg, CardBody, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";

import { useUserAuth } from "../../context/userAuthContext";

const Hero = () => {

  const { user, anonymousSignIn } = useUserAuth();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [trigger, setTrigger] = useState("");

  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(!user) await anonymousSignIn();
    if(email){
    try {
      await setDoc(doc(db, "registered", email), {
        name: name,
        email: email,
        message: message
      });
      setName("");
      setEmail("");
      setMessage("");
      setTrigger("Alert");
    } catch (error) {
      setError(error.message);
      console.error("Error adding document: ", error);
    };
  }
    else { setError("Please enter email address") };
  };

    return (
      <>
        <div className="position-relative">
          {/* Hero for FREE version */}
          <section className="section section-hero section-shaped">
            {/* Background circles */}
            <div className="shape shape-style-1 shape-primary">
              <span className="span-150" />
              <span className="span-50" />
              <span className="span-50" />
              <span className="span-75" />
              <span className="span-100" />
              <span className="span-75" />
              <span className="span-50" />
              <span className="span-100" />
              <span className="span-50" />
              <span className="span-100" />
            </div>
            <Container className="shape-container d-flex align-items-center py-lg">
              <div className="col px-0">
                <Row className="align-items-center justify-content-center">
                  <Col className="text-center" lg="9">
                    <img
                      alt="..."
                      className="img-fluid"
                      src={require("assets/img/brand/fynd-logo-byline-white.svg")}
                      style={{ width: "200px" }}
                    />
                    <h4 className="lead text-white">
                      AUGMENTED REALITY MARKETING FOR YOUR BUSINESS
                      </h4>
                      <h6 className="text-white">
                      Jersey friends, family, angels, demons join us on the pivot and register for updates
                      </h6>
                    <div className="btn-wrapper mt-5">
                      <Button
                        className="btn-white btn-icon mb-3 mb-sm-0"
                        color="default"
                        href=""
                        size="lg"
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="ni ni-send" />
                        </span>
                        <Link to="/#Register">
                        <span className="btn-inner--text">Register</span>
                        </Link>
                      </Button>{" "}
                      
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section section-lg section-shaped pb-250" id="History">
          <Container className="py-lg-md d-flex">
                <div className="col px-0">
                  <Row>
                    <Col lg="6">
                      <h1 className="display-3 text-primary">
                        Our History to Date{" "}
                        <span>A Really Successful Augmented Reality Proof of Concept</span>
                      </h1>
                      <p className="lead text-primary">
                        In 2018 we built <b className="text-warning">fynd</b>, an Apple iOS proof of concept to test our ideas with real people.
                        This was an augmented reality calendaring, event and find-a-friend app and the response was staggering.
                      </p>
                    </Col>
                  </Row>
                </div>
              </Container>
              <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-secondary"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>

          </section>
          <section className="section bg-secondary">
            <Container>
              <Row className="row-grid align-items-center">
                <Col md="6">
                  <Card className="bg-default shadow border-0">
                    <CardImg
                      alt="..."
                      src={require("assets/img/theme/appstore.JPG")}
                      top
                    />
                    <blockquote className="card-blockquote">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="svg-bg"
                        preserveAspectRatio="none"
                        viewBox="0 0 583 95"
                      >
                        <polygon
                          className="fill-default"
                          points="0,52 583,95 0,95"
                        />
                        <polygon
                          className="fill-default"
                          opacity=".2"
                          points="0,42 583,95 683,0 0,95"
                        />
                      </svg>
                      <h4 className="display-3 font-weight-bold text-white">
                        66th most popular download!
                      </h4>
                      <p className="lead text-italic text-white">
                        The fynd proof-of-concept was extremely well received achieving the 66th most popular download
                        with over 7,000 users in one day.
                      </p>
                    </blockquote>
                  </Card>
                </Col>
                <Col md="6">
                  <div className="pl-md-5">
                    <div className="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle mb-5">
                      <i className="ni ni-favourite-28" />
                    </div>
                    <h3>Our customers</h3>
                    <p className="lead">
                      We were blown away by the response from our customers.
                    </p>
                    <h4 className="text-warning">
                      "Omfg this is revolutionary"
                    </h4>
                    <h4 className="text-warning">
                      "this is actual genius"
                    </h4>
                    <h4 className="text-warning">
                      "Game changer"
                    </h4>
                    <p>
                      These are just a few of the thousands of incredible responses we received over our Facebook channel.
                      We were building great momentum and had plans to release an Andriod version but...
                    </p>
                    <a
                      className="font-weight-bold text-warning mt-5"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      Then COVID happened and nobody was meeting anymore!
                    </a>
                    <p>
                      We lost funding and were forced to re-think and re-structure. As with everyone, times were tough
                      but we feel excited that technology has moved on and our ideas are now even more compelling and achievable.
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section section-lg" id="Founders">
            <Container>
              <Row className="justify-content-center text-center mb-lg">
                <Col lg="8">
                  <h2 className="display-3">Founders</h2>
                  <p className="lead text-muted">
                    The founders of fynd have many years of experience is all aspects of business management, development,
                    and technology. Check out our stories on LinkedIn.
                  </p>
                </Col>
              </Row>
              <Row>
                
                <Col className="mb-5 mb-lg-0" lg="4" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/theme/Phil.JPG")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center mt-4">
                      <h5 className="title">
                        <span className="d-block mb-1">Phil</span>
                        <small className="h6 text-muted">
                          Director
                        </small>
                      </h5>
                      <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="primary"
                          href="#pablo"
                          id="tooltip1001"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fa fa-twitter" />
                          <UncontrolledTooltip delay={0} target="tooltip1001">
                          Twitter @PhilBeville
                          </UncontrolledTooltip>
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="primary"
                          href="https://www.linkedin.com/in/philipbeville"
                          id="tooltip1003"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fa fa-linkedin" />
                          <UncontrolledTooltip delay={0} target="tooltip1003">
                          Connect with me on LinkedIn
                          </UncontrolledTooltip>
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="primary"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                          id="tooltip1002"
                        >
                          <i className="fa fa-envelope" />
                          <UncontrolledTooltip delay={0} target="tooltip1002">
                          Email phil@fynd.app
                          </UncontrolledTooltip>
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="4" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/theme/brett.png")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center mt-3">
                      <h5 className="title">
                        <span className="d-block mb-1">Brett</span>
                        <small className="h6 text-muted">Director</small>
                      </h5>
                      <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="warning"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fa fa-twitter" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="warning"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fa fa-linkedin" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="warning"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fa fa-envelope" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="4" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/theme/andy.png")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">Andy</span>
                        <small className="h6 text-muted">Director and Resident DJ</small>
                      </h5>
                      <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="success"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fa fa-twitter" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="success"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fa fa-linkedin" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="success"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fa fa-envelope" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-primary"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section section-hero section-shaped" id="Future">
          <div className="shape shape-style-1 shape-default alpha-4">
              <span className="span-150" />
              <span className="span-50" />
              <span className="span-50" />
              <span className="span-75" />
              <span className="span-100" />
              <span className="span-75" />
              <span className="span-50" />
              <span className="span-100" />
              <span className="span-50" />
              <span className="span-100" />
            </div>
            <Container className="pt-lg pb-300">
              <Row className="text-center justify-content-center">
                <Col lg="10">
                  <h2 className="display-3 text-white">The Future</h2>
                  <p className="lead text-white">
                    We have some exciting plans for the future, improving what we have built to date and developing/pivoting
                    to provide augmented reality marketing services for businesses.  We are currently raising finance so if you
                    would like to help us with that or just stay in touch for updates please register with us.
                  </p>
                  
                </Col>
              </Row>
            </Container>
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section section-lg pt-lg-0 section-contact-us" id="Register">
            <Container>
              <Row className="justify-content-center mt--300">
                <Col lg="8">
                  <Card className="bg-gradient-secondary shadow">
                    {trigger && <UncontrolledAlert color="default">Thank you, you have successfully registered</UncontrolledAlert>}
                    {error && <UncontrolledAlert color="danger">{error}</UncontrolledAlert>}
                    <CardBody className="p-lg-5">
                      <h4 className="mb-1">Register Here</h4>
                      <p className="mt-0">
                        To be kept up to date with developments.
                      </p>
                      <Form id="Register" role="form" onSubmit={ handleSubmit }>
                      <FormGroup
                      >
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-user-run" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Your name"
                            type="text"
                            name="name"
                            id="name"
                            onChange={event => setName(event.target.value)}
                            value={name}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup
                      >
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Email address"
                            name="email"
                            type="email"
                            id="email"
                            required="required"
                            onChange={event => setEmail(event.target.value)}
                            value={email}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-4">
                        <Input
                          className="form-control-alternative"
                          cols="80"
                          name="message"
                          id="message"
                          placeholder="Type a message..."
                          rows="4"
                          type="textarea"
                          onChange={event => setMessage(event.target.value)}
                          value={message}
                        />
                      </FormGroup>
                      <div>
                        <Button
                          block
                          className="btn-round"
                          color="primary"
                          size="lg"
                          type="submit"
                        >
                          Register
                        </Button>
                      </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </>
    );
  }

export default Hero;
