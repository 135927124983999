/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { UserAuthContextProvider } from "./context/userAuthContext";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/argon-design-system-react.scss?v1.1.0";

import Index from "views/Index.js";
import Login from "views/examples/Login.js";
import Register from "views/examples/Register.js";




ReactDOM.render(
  <UserAuthContextProvider>
  <Router>
    <Routes>
      <Route path="/" element={<Index />} />
      <Route path="/home-page" element={<Index />} />
      <Route path="/login-page" element={<Login />} />
      <Route path="/register-page" element={<Register />} />
    </Routes>
  </Router>
  </UserAuthContextProvider>,
  document.getElementById("root")
);
